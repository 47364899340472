import BuyForm from "./BuyForm";
import axonImg from "./media/axon2.jpg";
import HomeVideo from "../../components/HomeVideo";
import "./buyTokens.css";

export default function Buytokens() {
  

  return(
    <>
      <div className="column">
        <div className="buyBox">
          <h1 className="h1Form">AXON - ICO</h1>
          <p className="pbuyForm">
            Educaverse is opening its whitelist for the launch of AXON Token,
            <br/>
            created to enhance the experience of the educational metaverse. 
          </p>
          <div className="ContextFormBox">         
            <BuyForm/>        
          </div>
          <br></br>
        </div>
      </div>
      <div className="column">
        <img src={axonImg} className="axonImg" alt=""/>     
      </div>
      <div className="buy-video">
          <HomeVideo/>
      </div>  
      
      
    </>
  )
    
}

  