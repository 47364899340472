import { useContext, useState } from "react";
// import ERC20 from "../../../contractAbi/ERC20.json";
import AXONabi from "../../../contractAbi/axonAbi.json";
import AXONSALEabi from "../../../contractAbi/axonSaleAbi.json";
import abi from "../../../contractAbi/abi.json";
import "./buyform.css";
import { types } from "../../../store/storeReducer";
import { StoreContext } from "../../../store/StoreProvider";
import swal from 'sweetalert';

var Web3 = require('web3');

const dai = "0x6b175474e89094c44da98b954eedeac495271d0f"; //eth main
const axon = "0x9E63a03F62a25844E102c645c2e47963CB8A272D"; //eth main
const axonSale = "0x7b57511a4cf419F5d422C2d0022e1e02c844670d"; //eth main


export default function BuyForm(){
  const [store, dispatch] = useContext(StoreContext);
 // const {walletAccount} = store;

  const [walletAddress, setWalletAddress] = useState("");
  const [tokenBalance, settokenBalance] = useState("");
  const [axonPrice, setaxonPrice] = useState("");
  const [daiCost, setdaiCost] = useState("");
  const [canBuybool, setcanbuybool] = useState(false);
  const [buyText, setbuyText]  = useState("BUY")
 
  
  let daiContract = "";
  //let axonContract = "";
  let axonSaleContract = "";
  
  const ethEnabled = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
      window.web3 = new Web3(window.ethereum);
      await initContracts();
      setWalletAddress(accounts[0]);
      dispatch({
        type: types.productWallet,
        payload: {address: accounts[0] }
      })
      return true;
    }    
    
    return false;
  } 
  
  async function initContracts(){
    
    daiContract = new window.web3.eth.Contract(abi, dai);    
    axonSaleContract = new window.web3.eth.Contract(AXONSALEabi, axonSale);
   // axonContract = new window.web3.eth.Contract(AXONabi, axon);
    
  }


  async function getTokenBalance() {  
    if(true){
      const balance = await daiContract.methods.balanceOf(walletAddress).call();
      const price = await axonSaleContract.methods.getPrice().call();
      settokenBalance(balance);
      setaxonPrice(toEther(price));
    }else{

      settokenBalance(1);
    }
  }

  async function aproveAndBuy() {
    const cantOfAxon = (daiCost.toString() / axonPrice.toString());
    const cantOfAxonInWei = Web3.utils.toWei(cantOfAxon.toString(), 'ether');
    await initContracts();
    const alloawance = await daiContract.methods.allowance(walletAddress, axonSale).call();
   
    if(alloawance.toString()<"10000000000"){
      setbuyText('APPROVING...')
      await approve().then(()=> {setbuyText('BUYING...')})
      await buy(cantOfAxonInWei).then(() => {getTokenBalance()});
    }else{
      buy(cantOfAxonInWei).then(() => {
        getTokenBalance();
        setbuyText("BUY");
        swal("Congratulations, you buy " + cantOfAxon + "AXON for " + daiCost + "DAIs")
      });
    }
  }

  async function approve(){
    // setbuyText('BUYING...')
    await daiContract.methods.approve(axonSale, "5000000000000000000000000000000").send({from: walletAddress })
  }

  async function buy(cantOfAxonInWei){
    const onSale = await axonSaleContract.methods.getAmountOnSale().call()
    
    if(parseInt(toEther(onSale)) >= parseInt(toEther(cantOfAxonInWei))){
      await axonSaleContract.methods.buyTokens(cantOfAxonInWei.toString()).send({from: walletAddress });
    }else{
      swal('Not enough tokens to sell ')
    }
        
  }


  //New Functions
 
  async function canBuy(){
    //await initContracts();
    let canBuy = await axonSaleContract.methods.canBuy().call();
    setcanbuybool(canBuy);
    
  }

  function calculateCostInDai(tokensToBuy){
    return (tokensToBuy * axonPrice).toString();
  }
  
  const handleChange = event => {
    const cost =  calculateCostInDai(event.target.value.toString());  
    setdaiCost(cost);
  };

  function toEther(num){
    return Web3.utils.fromWei(num.toString(), 'ether');
  }

  if(tokenBalance === ""){
    ethEnabled().then( res=> {getTokenBalance().then( res=> {canBuy().then(res =>getTokenBalance())})})
  }

  return(
    <>
    {walletAddress !== ""
     ? <div className="buyForm">
      {canBuybool
      ? <>
        <p> Balance: {toEther(tokenBalance)} DAIs</p>        
        <p className="p-buyForm"> Axon price: {axonPrice} DAIs</p>          
        <form>     
          <h3 className="h3Amount">AXON AMOUNT</h3>     
          <input type="number" disabled={toEther(tokenBalance) < axonPrice}  onChange={handleChange} id="message" placeholder="Cant of Axon to buy" className="inputForm"/>
          <h3 className="h3Amount">DAIs COST</h3>  
          <input 
            type="text"  
            disabled="true" 
            placeholder="cost in DAI" 
            value={'Cost: '+daiCost + " DAIs"} 
            className={(parseInt(toEther(tokenBalance)) < parseInt(axonPrice) ? "inputForm" : "inputForm2")}/>
          <button 
            type="button" 
            disabled={toEther(tokenBalance) < axonPrice} 
            onClick={aproveAndBuy} 
            className={(toEther(tokenBalance) <axonPrice ? "buyFormButton" : "buyFormButtonPointer")}
          >
            {buyText} 
          </button>
        </form>
        </>
      : <p> There are no tokens for sale</p>
      }
      </div>
     : <p> Please conect your wallet first </p>
    }
    
    </>
  )

}